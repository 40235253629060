exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-coach-js": () => import("./../../../src/pages/coach.js" /* webpackChunkName: "component---src-pages-coach-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-introductie-js": () => import("./../../../src/pages/introductie.js" /* webpackChunkName: "component---src-pages-introductie-js" */),
  "component---src-pages-klachtenregeling-js": () => import("./../../../src/pages/klachtenregeling.js" /* webpackChunkName: "component---src-pages-klachtenregeling-js" */),
  "component---src-pages-meestgestelde-vragen-js": () => import("./../../../src/pages/meestgestelde-vragen.js" /* webpackChunkName: "component---src-pages-meestgestelde-vragen-js" */),
  "component---src-pages-privacyverklaring-js": () => import("./../../../src/pages/privacyverklaring.js" /* webpackChunkName: "component---src-pages-privacyverklaring-js" */),
  "component---src-pages-traject-js": () => import("./../../../src/pages/traject.js" /* webpackChunkName: "component---src-pages-traject-js" */)
}

